// Libraries
import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Tags from '../components/tags';
// Styles
import * as styles from './blog-post.module.css';
// Utils
import { BLOG_SHOW } from '../utils/routeConstants';

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost');
    const previous = get(this.props, 'data.previous');
    const next = get(this.props, 'data.next');

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={post.description}
          image={`http:${post?.heroImage?.resize.src}`}
        />
        <Hero
          image={post.heroImage?.gatsbyImageData}
          title={post.title}
          content={post.description}
        />
        <div className={styles.container}>
          <span className={styles.meta}>
            <time dateTime={post.rawDate}>{post.publishedDate}</time> ~{' '}
            {post.body.childMarkdownRemark.timeToRead} minute read
          </span>
          <div className={styles.article}>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
            <Tags tags={post.tags} />
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={BLOG_SHOW(previous.slug)} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={BLOG_SHOW(next.slug)} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      rawDate: publishedDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      tags
      description
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`;
